import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e298aefe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-2 d-flex justify-center align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_btn, {
      class: "mx-1",
      size: "small",
      density: "compact",
      icon: "mdi-chevron-left",
      rounded: "",
      disabled: !_ctx.hasPervious,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage(_ctx.innerPage - 1)))
    }, null, 8, ["disabled"]),
    _createVNode(_component_v_text_field, {
      class: "mx-1 page",
      modelValue: _ctx.pageDisplay,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageDisplay) = $event)),
      label: "Page",
      variant: "underlined",
      density: "compact",
      "hide-details": "",
      onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setPage($event.target.value))),
      onFocus: _cache[3] || (_cache[3] = ($event: any) => ($event.target.select()))
    }, null, 8, ["modelValue"]),
    _createVNode(_component_v_btn, {
      class: "mx-1",
      size: "small",
      density: "compact",
      icon: "mdi-chevron-right",
      rounded: "",
      disabled: !_ctx.hasNext,
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setPage(_ctx.innerPage + 1)))
    }, null, 8, ["disabled"]),
    _createVNode(_component_v_text_field, {
      class: "ml-4 mr-1 page-size",
      modelValue: _ctx.pageSizeDisplay,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSizeDisplay) = $event)),
      label: "Page Size",
      variant: "underlined",
      density: "compact",
      "hide-details": "",
      onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setPageSize($event.target.value))),
      onFocus: _cache[7] || (_cache[7] = ($event: any) => ($event.target.select()))
    }, null, 8, ["modelValue"])
  ]))
}