<template>
  <v-sheet class="code-editor pa-4 my-2" color="grey-lighten-3" rounded>
    <v-label>Source Code</v-label>
    <div :id="id" class="code-editor"></div>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BfCodeEditorViewModel from './bf-code-editor.vue.model';

export default defineComponent({
  name: 'bf-code-editor',
  props: {
    modelValue: { type: String, required: true },
    currentInstruction: { type: Number as PropType<number | undefined>, default: undefined },
    readonly: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  expose: ['scrollToCodePointer', 'indentSelectionMore', 'indentSelectionLess'],
  data() {
    return {
      vm: new BfCodeEditorViewModel(),
    }
  },
  computed: {
    id(): string {
      return 'codeEditor_' + this.$.uid;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.vm.source) {
        return;
      }
      this.vm.source = this.modelValue;
    },
    currentInstruction(newValue: number | undefined, oldValue: number | undefined) {
      if (newValue === oldValue) {
        return;
      }
      this.vm.hightlightCharAt(newValue);
    },
    readonly() {
      this.vm.readonly = this.readonly;
    },
  },
  async mounted() {
    await this.vm.initialize(this.id);
    this.vm.updateModelValue = (newValue) => this.$emit('update:modelValue', newValue);
    this.vm.source = this.modelValue;
    this.vm.readonly = this.readonly;
    this.vm.hightlightCharAt(this.currentInstruction);
  },
  methods: {
    scrollToCodePointer() {
      if (this.currentInstruction != null) {
        this.vm.scrollTo(this.currentInstruction);
      }
    },
    indentSelectionMore() {
      this.vm.indentSelection('more');
    },
    indentSelectionLess() {
      this.vm.indentSelection('less');
    },
  }
})
</script>

<style scoped>

  .code-editor {
    width: 100%;
    outline: none;
  }

</style>
