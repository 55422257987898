import { BfFile } from "@/utils/file";
import { db } from "@/utils/indexed-db/db";
import { Router } from "vue-router";

export default class FilesViewModel {

    public files: BfFile[] = [];
    public renameFileId: number | undefined = undefined;
    public renameNewTitle: string = "";
    public showPersistentStorageDialog: boolean = false;
    public isPersistentStorageEnabled: boolean | undefined = undefined;
    public isPersistentStorageEnabledSuccess: boolean | undefined = undefined;

    private _router!: Router;

    public async initialize(router: Router): Promise<void> {
        this._router = router;
        navigator.storage.persisted().then(persisted => this.isPersistentStorageEnabled = persisted);
        await this.updateFiles();
    }

    public async openFile(id: number): Promise<void> {
        this._router.push({ name: 'editor', params: { id } });
    }

    public async newFile(): Promise<number> {
        const filesStore = await db.files();
        const id = await filesStore.add({
            title: "new file",
        });
        await this.updateFiles();
        return id;
    }

    public async deleteFile(id: number): Promise<void> {
        const filesStore = await db.files();
        await filesStore.delte(id);
        await this.updateFiles();
    }

    public async renameFile(id: number, newTitle: string): Promise<void> {
        const file = this.files.find(f => f.id === id);
        if (!file) {
            throw new Error("File not found");
        }
        file.title = newTitle;
        const filesStore = await db.files();
        await filesStore.put(file);
        await this.updateFiles();
    }

    public async cloneFile(id: number): Promise<number> {
        const file = this.files.find(f => f.id === id);
        if (!file) {
            throw new Error("File not found");
        }
        const filesStore = await db.files();
        const newId = await filesStore.add({ ...file, id: undefined, title: `${file.title} - Clone` });
        await this.updateFiles();
        return newId;
    }

    public rename(id: number): void {
        this.renameFileId = id;
        this.renameNewTitle = this.files.find(f => f.id === id)?.title ?? "";
    }

    public cancleRename(): void {
        this.renameFileId = undefined;
        this.renameNewTitle = "";
    }

    public async confirmRename(): Promise<void> {
        if (!this.renameFileId) {
            return;
        }
        if (this.renameNewTitle !== this.files.find(f => f.id === this.renameFileId)?.title) {
            await this.renameFile(this.renameFileId, this.renameNewTitle);
        }
        this.cancleRename();
    }

    public openPersistentStorageDialog(): void {
        this.showPersistentStorageDialog = true;
    }

    public closePersistentStorageDialog(): void {
        this.showPersistentStorageDialog = false;
        this.isPersistentStorageEnabledSuccess = undefined;
    }

    public async enablePersistentStorage(): Promise<void> {
        const persisted = await navigator.storage.persist();
        this.isPersistentStorageEnabled = persisted;
        this.isPersistentStorageEnabledSuccess = persisted;
    }

    private async updateFiles(): Promise<void> {
        const filesStore = await db.files();
        const files = await filesStore.getAll();
        files.sort((a, b) => (b.modified || b.created).getTime() - (a.modified || a.created).getTime());
        this.files = files;
    }
}
