import { Settings } from "../file";
import { getWorkerCode } from "./worker-preset";

type AnonymousInstr = { originalCount: number, sourceIndex: number | string };
type AnonymousCombinedInstr = { repeat: number, sourceIndices: (number | string)[] } & AnonymousInstr;
type AnonymousReversableInstr = { reverse: boolean } & AnonymousInstr;
type AnonymousGroupedInstr = { loopInstrs: Instrs, originalCountPerLoop: number, sourceIndexOfLoopEnd: number } & AnonymousInstr;
type IncInstr = { id: 'inc' } & AnonymousCombinedInstr & AnonymousReversableInstr;
type MoveInstr = { id: 'move' } & AnonymousCombinedInstr & AnonymousReversableInstr;
type PrintInstr = { id: 'print' } & AnonymousCombinedInstr;
type ReadInstr = { id: 'read' } & AnonymousCombinedInstr;
type LoopInstr = { id: 'loop' } & AnonymousGroupedInstr;
type DebuggerInstr = { id: 'debugger' } & AnonymousInstr;
type Instr = IncInstr | MoveInstr | PrintInstr | ReadInstr | LoopInstr | DebuggerInstr;
type Instrs = Instr[];
type SingleStepInstr = 'inc' | 'dec' | 'moveRight' | 'moveLeft' | 'print' | 'read';
type GeneratedCode = { current: string, currentAsync: string, functions?: string, functionsAsync?: string, requiresAsync?: boolean, usedInstrs?: SingleStepInstr[] };

export default class BrainfuckCompiler {

    private readonly _asyncRequired: string = "throw new Error('Async execution required');";
    private readonly _notDebuggable: string = "throw new Error('Async execution disallowed');";

    private _settings: Settings;
    private _counter: number = 0;

    constructor(settings: Settings) {
        this._settings = settings;
    }

    public compile(source: string, debuggable: boolean) {
        return this.buildWorker(this.groupByCharacter(source, debuggable), source.length, debuggable);
    }

    private groupByCharacter(source: string, debuggable: boolean): Instrs {
        const instrs = this.groupByCharacterRecursive(source, 0, debuggable);
        if (!instrs.finished) {
            throw new Error("Bracket mismatch: Unexpected ']'");
        }
        return instrs.instrs;
    }

    private groupByCharacterRecursive(source: string, start: number, debuggable: boolean): { finished: boolean, end: number, instrs: Instrs } {
        const instrs: Instrs = [];
        for (let i = start; i < source.length; i++) {
            const char = source[i];
            if (char !== '+'
                    && char !== '-'
                    && char !== '>'
                    && char !== '<'
                    && char !== '.'
                    && char !== ','
                    && char !== '['
                    && char !== ']'
                    && (!debuggable || char !== '#')) {
                continue;
            }
            if (char === '#') {
                instrs.push({ id: 'debugger', originalCount: 0, sourceIndex: i });
                continue;
            }
            if (char === '[') {
                const loop = this.groupByCharacterRecursive(source, i + 1, debuggable);
                if (loop.finished) {
                    throw new Error("Bracket mismatch: Missing ']'");
                }
                instrs.push({ id: 'loop', originalCount: 1, originalCountPerLoop: 1, sourceIndex: i, sourceIndexOfLoopEnd: loop.end - 1, loopInstrs: loop.instrs });
                i = loop.end - 1;
                continue;
            } else if (char === ']') {
                return { finished: false, end: i + 1, instrs };
            }
            let count = 1;
            const indices = [i];
            for (i++; i < source.length; i++) {
                const char2 = source[i];
                if (char === char2) {
                    count++;
                    indices.push(i);
                    continue;
                }
                if (char2 === '+'
                        || char2 === '-'
                        || char2 === '>'
                        || char2 === '<'
                        || char2 === '.'
                        || char2 === ','
                        || char2 === '['
                        || char2 === ']'
                        || (debuggable && char2 === '#')) {
                    break;
                }
            }
            i--;
            let instr: ('inc' | 'move' | 'print' | 'read') & Instr['id'];
            let reverse: boolean | undefined = undefined;
            switch (char) {
                case '+':
                case '-':
                    instr = 'inc';
                    reverse = char === '-';
                    break;
                case '>':
                case '<':
                    instr = 'move';
                    reverse = char === '<';
                    break;
                case '.':
                    instr = 'print';
                    break;
                case ',':
                    instr = 'read';
                    break;
            }
            instrs.push({ id: instr, originalCount: count, sourceIndex: indices[0], reverse: reverse!, repeat: count, sourceIndices: indices });
        }
        return { finished: true, end: source.length, instrs };
    }

    private buildWorker(instrs: Instrs, sourceLength: number, debuggable: boolean): string {
        const generated = this.generateInstrsJs(instrs, sourceLength, debuggable);
        const run = generated.requiresAsync ? generated.currentAsync : generated.current;
        let funcs = generated.functions ?? "";
        if (generated.requiresAsync) {
            funcs += (generated.functionsAsync ?? "");
        }
        let singleStepFuncs = "";
        if (generated.usedInstrs) {
            singleStepFuncs = this.generateSingleStepFunctions(generated.usedInstrs);
        }
        return getWorkerCode()
            .replace('__TAPE_INIT__', '0;' + this.createTapeJs() + '0')
            .replace('__RUN__', '0;' + run + '0')
            .replace('__FUNCS__', funcs)
            .replace('__STEPS__', singleStepFuncs);
    }

    private createTapeJs(): string {
        const cellSize = this._settings.cellSize;
        let bitSize;
        if (cellSize <= 2**8) {
            bitSize = 8;
        } else if (cellSize <= 2**16) {
            bitSize = 16;
        } else if (cellSize <= 2**32) {
            bitSize = 32;
        } else {
            throw new Error("Cell size too big");
        }
        return `this._tape=new Uint${bitSize}Array(${this._settings.tapeSize});`;
    }

    private generateInstrsJs(instrs: Instrs, sourceLength: number, debuggable: boolean): GeneratedCode {
        const generated = this.instrsToJs(instrs, sourceLength, 0, debuggable);
        if (instrs.length > 0) {
            const code = `this._codePointer=${sourceLength};`;
            generated.current += code;
            generated.currentAsync += code;
        }
        // const print = "console.log('Executed Instructions: '+this._executedInstrs);";
        // generated.current += print;
        // generated.currentAsync += print;
        return generated;
    }

    private instrsToJs(instrs: Instrs, lastSourceIndex: number, lastOriginalCount: number, debuggable: boolean): GeneratedCode {
        const generated = { current: "", currentAsync: "", functions: "", functionsAsync: "", usedInstrs: [] } as GeneratedCode;
        let instrsCountForCode = 0;
        let instrsCountForDataSend = 0;
        for (const instr of instrs) {
            instrsCountForCode += instr.originalCount;
            const generatedInstr = this.instrToJs(instr, debuggable);
            generated.current += generatedInstr.current;
            generated.currentAsync += generatedInstr.currentAsync;
            generated.functions += (generatedInstr.functions ?? "");
            generated.functionsAsync += (generatedInstr.functionsAsync ?? "");
            generated.requiresAsync ||= generatedInstr.requiresAsync;
            if (generatedInstr.usedInstrs) {
                generated.usedInstrs = [...new Set([...generated.usedInstrs!, ...generatedInstr.usedInstrs])];
            }
            instrsCountForDataSend += instr.originalCount;
            if (instrsCountForDataSend >= 10000) {
                const send = `this.sendDataIfNecessary(${instr.sourceIndex},${instrsCountForCode});`;
                generated.current += send;
                generated.currentAsync += send;
                instrsCountForCode = 0;
                instrsCountForDataSend -= 10000;
            }
        }
        instrsCountForCode += lastOriginalCount;
        if (instrsCountForCode !== 0) {
            const send = `this.sendDataIfNecessary(${lastSourceIndex},${instrsCountForCode});`;
            generated.current += send;
            generated.currentAsync += send;
        }
        return generated;
    }

    private instrToJs(instr: Instr, debuggable: boolean): GeneratedCode {
        let generated: GeneratedCode;
        switch (instr.id) {
            case 'inc':
                generated = this.incInstrToJs(instr, debuggable);
                break;
            case 'move':
                generated = this.moveInstrToJs(instr, debuggable);
                break;
            case 'print':
                generated = this.printInstrToJs(instr, debuggable);
                break;
            case 'read':
                generated = this.readInstrToJs(instr, debuggable);
                break;
            case 'loop':
                generated = this.loopInstrToJs(instr, debuggable);
                break;
            case 'debugger':
                generated = this.breakpointInstrToJs(instr, debuggable);
                break;
        }
        return generated;
    }

    private regularInstrToJs(instr: AnonymousCombinedInstr, singleStepInstr: SingleStepInstr, instrJs: string, debuggable: boolean): GeneratedCode {
        if (debuggable) {
            let resultAsync = "if(this._singleStep){";
            resultAsync += `await this.${singleStepInstr}SingleStep(${instr.repeat},[${instr.sourceIndices}]);`;
            resultAsync += "}else{";
            resultAsync += instrJs;
            resultAsync += "}";
            return { current: instrJs, currentAsync: resultAsync, usedInstrs: [singleStepInstr] };
        } else {
            return { current: instrJs, currentAsync: this._notDebuggable };
        }
    }

    private incInstrToJs(instr: IncInstr, debuggable: boolean): GeneratedCode {
        return this.regularInstrToJs(instr, instr.reverse ? 'dec' : 'inc', this.incLogicJs(instr), debuggable);
    }

    private moveInstrToJs(instr: MoveInstr, debuggable: boolean): GeneratedCode {
        return this.regularInstrToJs(instr, instr.reverse ? 'moveLeft' : 'moveRight', this.moveLogicJs(instr), debuggable);
    }

    private printInstrToJs(instr: PrintInstr, debuggable: boolean): GeneratedCode {
        return this.regularInstrToJs(instr, 'print', this.printLogicJs(instr), debuggable);
    }

    private readInstrToJs(instr: ReadInstr, debuggable: boolean): GeneratedCode {
        return this.regularInstrToJs(instr, 'read', this.readLogicJs(instr), debuggable);
    }

    private loopInstrToJsInternal(instr: LoopInstr, loopBody: string, singleStep: boolean): string {
        let result = "";
        if (singleStep) {
            result += `if(this._singleStep){await this.wait(${instr.sourceIndex});}`;
        }
        result += "while(this._tape[this._tapePointer]!==0){";
        result += loopBody;
        if (singleStep) {
            result += `if(this._singleStep){await this.wait(${instr.sourceIndexOfLoopEnd});}`;
        }
        result += "}";
        return result;
    }

    private loopInstrToJs(instr: LoopInstr, debuggable: boolean): GeneratedCode {
        const generatedInstrs = this.instrsToJs(instr.loopInstrs, instr.sourceIndexOfLoopEnd, instr.originalCountPerLoop, debuggable);
        const generated: GeneratedCode = {
            current: this._asyncRequired,
            currentAsync: this._notDebuggable,
            functions: generatedInstrs.functions,
            functionsAsync: generatedInstrs.functionsAsync,
            requiresAsync: generatedInstrs.requiresAsync,
            usedInstrs: generatedInstrs.usedInstrs,
        };

        let loopBody = "";
        let loopBodyAsync = "";
        let genFunction = "";
        let genFunctionAsync = "";
        if (!instr.loopInstrs.some(i => i.id === 'loop')) {
            loopBody = generatedInstrs.current;
            loopBodyAsync = generatedInstrs.currentAsync;
        } else {
            const counter = this._counter++;
            const funcName = `run${counter}`;
            const funcNameAsync = `${funcName}Async`;
            genFunction = `${funcName}(){${generatedInstrs.current}}`;
            genFunctionAsync = `async ${funcNameAsync}(){${generatedInstrs.currentAsync}}`;

            loopBody = `this.${funcName}();`;

            if (!generated.requiresAsync) {
                loopBodyAsync += "if(this._singleStep){";
            }
            loopBodyAsync += `await this.${funcNameAsync}();`;
            if (!generated.requiresAsync) {
                loopBodyAsync += "}else{";
                loopBodyAsync += loopBody;
                loopBodyAsync += "}";
            }
        }

        if (!generated.requiresAsync) {
            generated.functions ??= "";
            generated.functions += genFunction;
            generated.current = this.loopInstrToJsInternal(instr, loopBody, false);
        }
        if (debuggable) {
            generated.functionsAsync ??= "";
            generated.functionsAsync += genFunctionAsync;
            generated.currentAsync = this.loopInstrToJsInternal(instr, loopBodyAsync, true);
        }
        return generated;
    }

    private breakpointInstrToJs(instr: DebuggerInstr, debuggable: boolean): GeneratedCode {
        if (debuggable) {
            return { current: this._asyncRequired, currentAsync: `await this.wait(${instr.sourceIndex});`, requiresAsync: true };
        } else {
            return { current: "", currentAsync: this._notDebuggable };
        }
    }

    private generateSingleStepFunctions(usedInstrs: SingleStepInstr[]): string {
        let result = "";
        for (const instr of new Set(usedInstrs)) {
            switch (instr) {
                case 'inc':
                case 'dec':
                    result += this.singleStepIncInstrToJs(instr);
                    break;
                case 'moveRight':
                case 'moveLeft':
                    result += this.singleStepMoveInstrToJs(instr);
                    break;
                case 'print':
                    result += this.singleStepPrintInstrToJs();
                    break;
                case 'read':
                    result += this.singleStepReadInstrToJs();
                    break;
            }
        }
        return result;
    }

    private regularSingleStepInstrToJs(instr: SingleStepInstr, instrJs: (codePointer: string) => string): string {
        let result = `async ${instr}SingleStep(count,codePointers){`;
        result += "for(let i=0;i<count;i++){";
        result += "if(this._singleStep){";
        result += "await this.wait(codePointers[i]);";
        result += "}";
        result += instrJs("codePointers[i]");
        result += "}";
        result += "}";
        return result;
    }

    private singleStepIncInstrToJs(instr: 'inc' | 'dec'): string {
        return this.regularSingleStepInstrToJs(instr, (codePointer: string) => this.incLogicJs({
            originalCount: 1,
            repeat: 1,
            reverse: instr === 'dec',
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }));
    }

    private singleStepMoveInstrToJs(instr: 'moveRight' | 'moveLeft'): string {
        return this.regularSingleStepInstrToJs(instr, (codePointer: string) => this.moveLogicJs({
            originalCount: 1,
            repeat: 1,
            reverse: instr === 'moveLeft',
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }));
    }

    private singleStepPrintInstrToJs(): string {
        return this.regularSingleStepInstrToJs('print', (codePointer: string) => this.printLogicJs({
            originalCount: 1,
            repeat: 1,
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }));
    }

    private singleStepReadInstrToJs(): string {
        return this.regularSingleStepInstrToJs('read', (codePointer: string) => this.readLogicJs({
            originalCount: 1,
            repeat: 1,
            sourceIndex: codePointer,
            sourceIndices: [codePointer],
        }));
    }

    private incLogicJs(instr: AnonymousCombinedInstr & AnonymousReversableInstr): string {
        const value = instr.repeat * (instr.reverse ? -1 : 1);
        const cellSize = this._settings.cellSize;
        const maxValue = this._settings.signedCell ? Math.ceil(cellSize / 2) - 1 : cellSize - 1;
        const minValue = this._settings.signedCell ? -Math.floor(cellSize / 2) : 0;
        switch (this._settings!.cellOverflow) {
            case 'wrap':
                if (cellSize === 2**8 || cellSize === 2**16 || cellSize === 2**32) {
                    return `this._tape[this._tapePointer]+=${((value % cellSize) + cellSize) % cellSize};`;
                } else {
                    return `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                }
            case 'ignore':
                if (this._settings.signedCell) {
                    let result = "";
                    if (value > 0) {
                        if (maxValue - value >= 0) {
                            result += `if(this._tape[this._tapePointer]>${maxValue - value}&&this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]>${cellSize + maxValue - value}||this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        }
                        result += `this._tape[this._tapePointer]=${maxValue};`;
                    } else {
                        if (minValue - value < 0) {
                            result += `if(this._tape[this._tapePointer]<${cellSize + minValue - value}&&this._tape[this._tapePointer]>${maxValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]<${minValue - value}||this._tape[this._tapePointer]>${maxValue}){`;
                        }
                        result += `this._tape[this._tapePointer]=${cellSize + minValue};`;
                    }
                    result += "}else{";
                    result += `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                    result += "}";
                    return result;
                } else {
                    if (value > 0) {
                        return `this._tape[this._tapePointer]=Math.min(this._tape[this._tapePointer]+${value},${maxValue});`;
                    } else {
                        return `this._tape[this._tapePointer]=Math.max(this._tape[this._tapePointer]-${-value},${minValue});`;
                    }
                }
            case 'throw': {
                let result = "";
                if (value > 0) {
                    if (this._settings.signedCell) {
                        if (maxValue - value >= 0) {
                            result += `if(this._tape[this._tapePointer]>${maxValue - value}&&this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]>${cellSize + maxValue - value}||this._tape[this._tapePointer]<${cellSize + minValue}){`;
                        }
                    } else {
                        result += `if(this._tape[this._tapePointer]>${maxValue - value}){`;
                    }
                    result += `const at=(${cellSize + maxValue}-this._tape[this._tapePointer])%${cellSize};`;
                    result += `this._tape[this._tapePointer]=${maxValue};`;
                    result += `this.sendErrorCellOverflow([${instr.sourceIndices}][at]);`;
                } else {
                    if (this._settings.signedCell) {
                        if (minValue - value < 0) {
                            result += `if(this._tape[this._tapePointer]<${cellSize + minValue - value}&&this._tape[this._tapePointer]>${maxValue}){`;
                        } else {
                            result += `if(this._tape[this._tapePointer]<${minValue - value}||this._tape[this._tapePointer]>${maxValue}){`;
                        }
                    } else {
                        result += `if(this._tape[this._tapePointer]<${minValue - value}){`;
                    }
                    result += `const at=(${cellSize + minValue}+this._tape[this._tapePointer])%${cellSize};`;
                    result += `this._tape[this._tapePointer]=${(cellSize + minValue) % cellSize};`;
                    result += `this.sendErrorCellUnderflow([${instr.sourceIndices}][at]);`;
                }
                result += "return;";
                result += "}";
                result += `this._tape[this._tapePointer]=(this._tape[this._tapePointer]+${((value % cellSize) + cellSize) % cellSize})%${cellSize};`;
                return result;
            }
        }
    }

    private moveLogicJs(instr: AnonymousCombinedInstr & AnonymousReversableInstr): string {
        const value = instr.repeat * (instr.reverse ? -1 : 1);
        const tapeSize = this._settings.tapeSize;
        switch (this._settings.tapeOverflow) {
            case 'wrap':
                return `this._tapePointer=(this._tapePointer+${((value % tapeSize) + tapeSize) % tapeSize})%${tapeSize};`;
            case 'ignore':
                if (value > 0) {
                    return `this._tapePointer=Math.min(this._tapePointer+${value},${tapeSize - 1});`;
                } else {
                    return `this._tapePointer=Math.max(this._tapePointer-${-value},0);`;
                }
            case 'throw':
                if (value > 0) {
                    let result = `if(this._tapePointer>=${tapeSize - value}){`;
                    result += `const at=${tapeSize - 1}-this._tapePointer;`;
                    result += `this._tapePointer=${tapeSize - 1};`;
                    result += `this.sendErrorTapeOverflow([${instr.sourceIndices}][at]);`;
                    result += "return;";
                    result += "}";
                    result += `this._tapePointer+=${value};`;
                    return result;
                } else {
                    let result = `if(this._tapePointer<${-value}){`;
                    result += `const at=this._tapePointer;`;
                    result += "this._tapePointer=0;";
                    result += `this.sendErrorTapeUnderflow([${instr.sourceIndices}][at]);`;
                    result += "return;";
                    result += "}";
                    result += `this._tapePointer-=${-value};`;
                    return result;
                }
        }
    }

    private printLogicJs(instr: AnonymousCombinedInstr): string {
        let result = "this._output.push(";
        result += Array(instr.repeat).fill("this._tape[this._tapePointer]").join(",");
        result += ");";
        return result;
    }

    private readLogicJs(instr: AnonymousCombinedInstr): string {
        const plusValueMinusOne = instr.repeat >= 2 ? "+" + (instr.repeat - 1) : "";
        let result = `if(this._inputPointer${plusValueMinusOne}>=this._input.length){`;
        if (this._settings.eof === 'noChange') {
            if (instr.repeat >= 2) {
                result += `if(this._inputPointer<this._input.length){`;
                result += "this._tape[this._tapePointer]=this._input[this._input.length-1];";
                result += "}";
            }
        } else {
            result += `this._tape[this._tapePointer]=${this._settings.eof === 'return0' ? 0 : this._settings.cellSize - 1};`;
        }
        result += "}else{";
        result += `this._tape[this._tapePointer]=this._input[this._inputPointer${plusValueMinusOne}];`;
        result += `this._inputPointer+=${instr.repeat};`;
        result += "}";
        return result;
    }
}
