<template>
  <v-app>
    <v-app-bar density="compact" class="position-sticky">
      <template v-slot:prepend>
        <v-btn v-if="hasBackButton" icon="mdi-arrow-left" @click="$emit('back')"></v-btn>
      </template>
      <v-app-bar-title>
        <div>
          <transition name="title" mode="out-in">
            <div :key="title">
              {{ title || 'BrainF' }}
            </div>
          </transition>
        </div>
      </v-app-bar-title>
      <template v-slot:append>
        <v-btn v-for="action in barActions" :key="action.id" :icon="action.icon" :disabled="resolve(action.disabled)" @click="action.onClick"></v-btn>
        <v-menu v-if="menuActions?.length">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
          </template>
          <v-list density="compact">
            <v-list-item v-for="action in menuActions" :key="action.id" @click="action.onClick">
              <template v-slot:prepend v-if="action.icon">
                <v-icon :icon="action.icon"></v-icon>
              </template>
              <v-list-item-title>{{ action.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-app-bar>
    <v-container :class="noPageScroll ? ['fill-height', 'd-flex', 'flex-column', 'align-stretch'] : []">
      <slot></slot>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export type Action = {
  id: string,
  icon?: string,
  title?: string,
  disabled?: boolean | (() => boolean),
  location: 'bar' | 'menu',
  onClick: () => void,
}

export default defineComponent({
  name: 'layout-default',
  props: {
    actions: { type: Array as PropType<Action[]>, default: () => [] },
    title: { type: String, default: '' },
    hasBackButton: { type: Boolean, default: false },
    noPageScroll: { type: Boolean, default: false },
  },
  data() {
    return {
    }
  },
  emits: ['back'],
  computed: {
    barActions() {
      return this.actions.filter(a => a.location === 'bar');
    },
    menuActions() {
      return this.actions.filter(a => a.location === 'menu');
    },
  },
  methods: {
    resolve<T>(value: T | (() => T)): T {
      return typeof value === 'function' ? (value as any)() : value;
    }
  },
})
</script>

<style scoped>
  .title-enter-active,
  .title-leave-active {
    transition: all .15s ease;
  }

  .title-enter-from {
    transform: translateY(-3px);
    opacity: 0;
  }

  .title-leave-to {
    transform: translateY(3px);
    opacity: 0;
  }
</style>
