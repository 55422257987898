import BrainfuckCompiler from "@/utils/brainfuck-compiler/brainfuck-compiler";
import { db } from "@/utils/indexed-db/db";
import { debounce } from "@/utils/utils";
import * as windows1252 from 'windows-1252';
import { BfFile, Settings, defaultSettings, fileOk } from "@/utils/file";

export type ToWorkerMessage = { type: 'input', data: { input: IntegerArray } } 
        | { type: 'data', data: { tape: IntegerArray | undefined, activeCell: number | undefined } }
        | { type: 'run' | 'continue' | 'step' };
export type FromWorkerMessage = { type: 'data', data: { output: number[], tape: IntegerArray, activeCell: number, currentInstruction: number } } 
        | { type: 'end' | 'breakpoint' }
        | { type: 'error', data: { msg: string }};
export type State = 'reset' | 'running' | 'debugging' | 'hitBreakpoint' | 'stopped' | 'error';

export type IntegerArray = Uint8Array | Uint16Array | Uint32Array;

export default class EditorViewModel {

    private readonly _maxOutputSize = 65536;

    public fileId?: number;
    public file?: BfFile;
    public tape?: IntegerArray;
    public activeCell: number | undefined = undefined;
    public currentInstruction: number | undefined = undefined;
    public state: State = 'reset';
    public errorMsg: string = "";
    public recentlySaved: boolean = false;
    public errorWhileSaving?: any;
    
    private _worker?: Worker;
    private _isReset: boolean = false;
    // private _cleanSrcToSrcIndex: number[] = [];
    private _outputCommited: string = "";
    private _outputUncommited: number[] = [];
    private _multiStepLongPressTimer: number | undefined = undefined;
    private _multiStepInterStepTimer: number | undefined = undefined;
    private _allowSingleStep: boolean = false;
    private _textEncoder?: TextEncoder;
    private _textDecoder?: TextDecoder;

    public get title(): string {
        return this.recentlySaved ? 'Saved' : this.file?.title ?? ' ';
    }
    
    public get src(): string {
        return this.fileOk() ? this.file!.content!.sourceCode : "";
    }

    public set src(value: string) {
        if (!this.fileOk() || value === this.file?.content?.sourceCode) {
            return;
        }
        this.file!.content!.sourceCode = value;
        this.saveFile();
    }

    public get input(): string {
        return this.fileOk() ? this.file!.content!.inputValue : "";
    }

    public set input(value: string) {
        if (!this.fileOk() || value === this.file?.content?.inputValue) {
            return;
        }
        this.file!.content!.inputValue = value;
        this.saveFile();
    }

    public get output(): string {
        return this._outputCommited + (this._outputUncommited.length === 0 ? "" : "\uFFFD");
    }

    public get settings(): Settings {
        return this.fileOk() ? this.file!.content!.settings! : defaultSettings();
    }

    public async initialize(fileId: string | string[]): Promise<void> {
        if (Array.isArray(fileId) || Number.isNaN(+fileId)) {
            throw new Error("Id is not a number");
        }
        this.fileId = +fileId;
        const filesStore = await db.files();
        this.file = await filesStore.get(this.fileId);
        this._textEncoder = new TextEncoder();
        this._textDecoder = new TextDecoder(this.settings.characterSet, { ignoreBOM: true });
    }

    public reset() {
        this.stop();
        this._outputCommited = "";
        this._outputUncommited = [];
        this.tape = undefined;
        this.activeCell = undefined;
        this.currentInstruction = undefined;
        this._isReset = true;
        this.state = 'reset';
        this.errorMsg = "";
    }

    public stop() {
        if (!this._worker) {
            return;
        }
        console.timeEnd("run");
        this._worker.terminate();
        this._worker = undefined;
        this.state = 'stopped';
    }

    public run() {
        this.prepareNewRun(false);
        this.state = 'running';
        this.sendMessageToWorker({ type: 'run' });
    }

    public debug() {
        this.prepareNewRun(true);
        this.state = 'debugging';
        this.sendMessageToWorker({ type: 'run' });
    }

    public continue() {
        this.prepareContinueRun();
        this.state = 'debugging';
        this.sendMessageToWorker({ type: 'continue' });
    }

    public step() {
        this.prepareContinueRun();
        this.sendMessageToWorker({ type: 'step' });
    }

    public multiStepStart() {
        clearTimeout(this._multiStepLongPressTimer);
        this._multiStepLongPressTimer = undefined;
        clearInterval(this._multiStepInterStepTimer);
        this._multiStepInterStepTimer = undefined;
        this._allowSingleStep = true;

        this._multiStepLongPressTimer = setTimeout(() => {
            clearTimeout(this._multiStepLongPressTimer);
            this._allowSingleStep = false;
            this._multiStepLongPressTimer = undefined;
            this._multiStepInterStepTimer = setInterval(() => {
                if (!this._worker) {
                    clearTimeout(this._multiStepLongPressTimer);
                    this._multiStepLongPressTimer = undefined;
                    clearInterval(this._multiStepInterStepTimer);
                    this._multiStepInterStepTimer = undefined;
                    return;
                }
                this.step()
            }, 20);
        }, 1000);
    }

    public multiStepStop(allowAction: boolean) {
        if (allowAction && this._allowSingleStep) {
            this._allowSingleStep = false;
            this.step();
        }
        clearTimeout(this._multiStepLongPressTimer);
        this._multiStepLongPressTimer = undefined;
        clearInterval(this._multiStepInterStepTimer);
        this._multiStepInterStepTimer = undefined;
    }

    private prepareNewRun(debuggable: boolean) {
        if (this._worker) {
            throw new Error('Worker is already running');
        }
        console.time("run");
        if (!this._isReset) {
            this.reset();
        }
        this._isReset = false;
        this._worker = this.createWorker(debuggable);
        this.sendMessageToWorker({ type: 'input', data: { input: this.stringToBytes(this.input, false)! } });
        this.prepareContinueRun();
    }

    private prepareContinueRun() {
        this.sendMessageToWorker({ type: 'data', data: { tape: this.tape, activeCell: this.activeCell } });
    }

    private createWorker(debuggable: boolean): Worker {
        const code = new BrainfuckCompiler(this.settings).compile(this.src, debuggable);
        const blob = new Blob([code], { type: 'application/javascript' });
        const blobUrl = URL.createObjectURL(blob);
        const worker = new Worker(blobUrl);
        URL.revokeObjectURL(blobUrl);
        worker.addEventListener("message", (event) => this.onMessageFormWorker(event));
        return worker;
    }

    // private computeCleanSrc(): string {
    //     const instructions = '+-<>.,[]#';
    //     let cleanSrc = '';
    //     this._cleanSrcToSrcIndex = [];
    //     for (let i = 0; i < this._src.length; i++) {
    //         const c = this._src[i];
    //         if (!instructions.includes(c)) {
    //             continue;
    //         }
    //         cleanSrc += c;
    //         this._cleanSrcToSrcIndex.push(i);
    //     }
    //     return cleanSrc;
    // }
    
    private onMessageFormWorker(event: MessageEvent<FromWorkerMessage>) {
        const msg = event.data;
        switch (msg.type) {
            case 'data':
                this.mirrorData(msg.data.output, msg.data.tape, msg.data.activeCell, msg.data.currentInstruction);
                break;
            case 'end':
                this.endRun();
                break;
            case 'breakpoint':
                this.hitBreakpoint();
                break;
            case 'error':
                this.handleError(msg.data.msg);
                break;
        }
    }

    private mirrorData(output: number[], tape: IntegerArray, activeCell: number, currentInstruction: number ) {
        this.processOutput(output);
        this.tape = tape;
        this.activeCell = activeCell;
        this.currentInstruction = currentInstruction;// == null ? undefined : this._cleanSrcToSrcIndex[currentInstruction];
    }

    private endRun() {
        this.stop();
    }

    private hitBreakpoint() {
        this.state = 'hitBreakpoint';
    }

    private handleError(msg: string) {
        if (!this._worker) {
            return;
        }
        this._worker.terminate();
        this._worker = undefined;
        this.state = 'error';
        this.errorMsg = msg;
    }

    private stringToBytes(value: string, undefinedIfNotPossible: boolean): IntegerArray | undefined {
        let normalized;
        switch (this.settings.newLine) {
            case 'n':
                normalized = value;
                break;
            case 'rn':
                normalized = value.replaceAll('\n', '\r\n');
                break;
            case 'r':
                normalized = value.replaceAll('\n', '\r');
                break;
        }
        let result;
        let encodingMaxValue;
        switch (this.settings.characterSet) {
            case 'ascii': {
                const encoded = windows1252.encode(normalized, { mode: 'replacement' });
                if (undefinedIfNotPossible && encoded.some(v => v === 0xFFFD)) {
                    return undefined;
                }
                result = new Uint8Array(encoded.map(v => v === 0xFFFD ? 127 : v));
                encodingMaxValue = 2**8 - 1;
                break;
            }
            case 'utf-8': {
                result = this._textEncoder!.encode(normalized);
                encodingMaxValue = 2**8 - 1;
                break;
            }
            case 'utf-16': {
                result = new Uint16Array(normalized.length);
                for (let i = 0, len = normalized.length; i < len; i++) {
                    result[i] = normalized.charCodeAt(i);
                }
                encodingMaxValue = 2**16 - 1;
                break;
            }
        }
        const cellSize = this.settings.cellSize;
        const cellMaxValue = this.settings.signedCell ? Math.ceil(cellSize / 2) - 1 : cellSize - 1;
        if (encodingMaxValue > cellMaxValue) {
            if (undefinedIfNotPossible && result.some(v => v >= cellSize)) {
                return undefined;
            }
            result = result.map(v => {
                let newV = (v % cellSize);
                if (newV > cellMaxValue) {
                    newV -= cellSize;
                }
                return newV;
            });
        }
        return result;
    }

    private bytesToString(value: number[]): string {
        const int8 = this.settings.characterSet !== 'utf-16';
        const arr = int8 ? new Uint8Array(value) : new Uint16Array(value);
        const result = this._textDecoder?.decode(arr) ?? "";
        return result;
    }

    private processOutput(value: number[]) {
        if (value.length === 0) {
            return;
        }
        const extended = this._outputUncommited.concat(value);
        let commited: number[];
        let uncommited: number[];
        switch (this.settings.characterSet) {
            case 'ascii': {
                commited = extended;
                uncommited = [];
                break;
            }
            case 'utf-8': {
                let i: number = 0;
                let back: number = 0;
                let v: number;
                while (extended.length > i && ((v = extended[extended.length - i - 1]) & 0x80) !== 0) {
                    i++;
                    if ((v & 0x40) === 0) {
                        continue;
                    }
                    if ((v & 0x20) === 0) {
                        back = i >= 2 ? 0 : i;
                        break;
                    }
                    if ((v & 0x10) === 0) {
                        back = i >= 3 ? 0 : i;
                        break;
                    }
                    if ((v & 0x08) === 0) {
                        back = i >= 4 ? 0 : i;
                        break;
                    }
                }
                if (back === 0) {
                    commited = extended;
                    uncommited = [];
                } else {
                    commited = extended.slice(0, -back);
                    uncommited = extended.slice(-back);
                }
                break;
            }
            case 'utf-16': {
                if ((extended[extended.length - 1] & 0xFC00) === 0xD800) {
                    commited = extended.slice(0, -1);
                    uncommited = extended.slice(-1);
                } else {
                    commited = extended;
                    uncommited = [];
                }
                break;
            }
        }
        if (commited.length) {
            this._outputCommited += this.bytesToString(commited);
        }
        const len = this._outputCommited.length;
        if (len >= this._maxOutputSize) {
            this._outputCommited = this._outputCommited.substring(len - this._maxOutputSize, len);
        }
        this._outputUncommited = uncommited;
    }

    public toChar(v: number): string {
        const uft8Extended = this.settings.characterSet === 'utf-8' && (v & 0xC0) === 0x80;
        const uft16Extended = this.settings.characterSet === 'utf-16' && (v & 0xFC00) === 0xDC00;
        const values = this._outputUncommited && (uft8Extended || uft16Extended) ? this._outputUncommited.concat(v) : [v];
        return this.bytesToString(values);
    }

    public fromChar(v: string): IntegerArray | undefined {
        return this.stringToBytes(v, true);
    }

    private sendMessageToWorker(message: ToWorkerMessage) {
        if (!this._worker) {
            throw new Error('No Worker is running');
        }
        this._worker.postMessage(message);
    }

    private fileOk() {
        return fileOk(this.file);
    }

    private saveFile() {
        debounce(async () => {
            try {
                if (this.file) {
                    const filesStore = await db.files();
                    await filesStore.put(this.file);
                    this.recentlySaved = true;
                    this.errorWhileSaving = undefined;
                    setTimeout(() => {
                        this.recentlySaved = false;
                    }, 1000);
                } else {
                    this.errorWhileSaving = "No file to save";
                }
            } catch (ex: any) {
                this.errorWhileSaving = ex;
            }
        }, 1000, this)();
    }
}
