import { parser } from "./lang";
import { HighlightStyle, LRLanguage, LanguageSupport, syntaxHighlighting, indentNodeProp, foldNodeProp, foldInside, delimitedIndent, continuedIndent } from "@codemirror/language";
import { Tag, styleTags, tags as t } from "@lezer/highlight";

const incDecTag = Tag.define();
const moveTag = Tag.define();
const loopTag = Tag.define();
const printReadTag = Tag.define();
const debuggerTag = Tag.define();
const commentTag = Tag.define();
const brainfuckHighlightStyle = HighlightStyle.define([
  { tag: incDecTag, color: "#770088" },
  { tag: moveTag, color: "#221199" },
  { tag: loopTag, color: "#008855" },
  { tag: printReadTag, color: "#FF0000", fontWeight: "bold", textShadow: "0px 0px 5px #FF0000" },
  { tag: debuggerTag, color: "#FF00FF" },
  { tag: commentTag, color: "#AA5500" },
]);

export const brainfuckHighlighter = syntaxHighlighting(brainfuckHighlightStyle);

export const brainfuckLanguage = LRLanguage.define({
  parser: parser.configure({
    props: [
      indentNodeProp.add({
        Loop: continuedIndent(),
        LoopComment: delimitedIndent({ closing: "]", align: false }),
      }),
      foldNodeProp.add({
        Loop: foldInside,
        LoopComment: foldInside,
      }),
      styleTags({
        IncDec: incDecTag,
        Move: moveTag,
        LoopStart: loopTag,
        LoopEnd: loopTag,
        Print: printReadTag,
        Read: printReadTag,
        Debugger: debuggerTag,
        Comment: commentTag,
        LoopComment: commentTag,
        CommentStart: commentTag,
        CommentEnd: commentTag,
      })
    ]
  }),
});


export function brainfuck() {
  return new LanguageSupport(brainfuckLanguage, [brainfuckHighlighter]);
}
