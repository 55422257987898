<template>
  <layout-default :title="`Settings - ${vm.file?.title ?? ''}`" :has-back-button="true" @back="$router.back()">
    <v-row>
      <v-col>
        <v-text-field
          v-model="vm.tapeSizeDisplay"
          label="Tape Size"
          density="comfortable"
          hide-details
          @change="vm.setTapeSize($event.target.value); vm.clearSaved()">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="vm.settings.tapeOverflow"
          label="Tape Over-/Underflow"
          :items="vm.tapeOverflowItems"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="vm.cellSizeDisplay"
          label="Cell Size"
          density="comfortable"
          hide-details
          @change="vm.setCellSize($event.target.value); vm.clearSaved()">
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="vm.cellSizeBitsDisplay"
          label="Cell Size Bits"
          density="comfortable"
          hide-details
          @change="vm.setCellSizeBits($event.target.value); vm.clearSaved()">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="vm.settings.signedCell"
          label="Signed Cell Values"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="vm.settings.cellOverflow"
          label="Cell Over-/Underflow"
          :items="vm.cellOverflowItems"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="vm.settings.characterSet"
          label="Character Set"
          :items="vm.characterSetItems"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="vm.settings.eof"
          label="End of Input"
          :items="vm.endOfInputItems"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          v-model="vm.settings.newLine"
          label="New Lines"
          :items="vm.newLinesItems"
          item-title="title"
          item-value="value"
          density="comfortable"
          hide-details
          @update:model-value="vm.clearSaved()">
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn class="text-none" @click="$router.back()" block>Close</v-btn>
      </v-col>
      <v-col>
        <v-btn class="text-none" @click="vm.save()" block>Save</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="vm.saved">
      <v-col>
        <v-alert ref="savedAlert" v-model="vm.saved" text="Settings saved" type="success" variant="tonal" closable></v-alert>
      </v-col>
    </v-row>
    <v-row v-if="vm.error">
      <v-col>
        <v-alert ref="errorAlert" :text="vm.error.toString()" type="error" variant="tonal"></v-alert>
      </v-col>
    </v-row>
  </layout-default>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SettingsViewModel from './settings-view.vue.model';
import { VAlert } from 'vuetify/lib/components/index.mjs';

export default defineComponent({
  name: 'settings-view',
  data() {
    return {
      vm: new SettingsViewModel(),
    }
  },
  watch: {
    "vm.saved"() {
      if (this.vm.saved) {
        this.$nextTick(() => {
          (this.$refs.savedAlert as VAlert).$el.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
    "vm.error"() {
      if (this.vm.error) {
        this.$nextTick(() => {
          (this.$refs.errorAlert as VAlert).$el.scrollIntoView({ behavior: 'smooth' });
        });
      }
    },
  },
  async beforeMount() {
    await this.vm.initialize(this.$route.params.id);
  },
})
</script>
