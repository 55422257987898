import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_layout_default = _resolveComponent("layout-default")!

  return (_openBlock(), _createBlock(_component_layout_default, {
    title: `Settings - ${_ctx.vm.file?.title ?? ''}`,
    "has-back-button": true,
    onBack: _cache[21] || (_cache[21] = ($event: any) => (_ctx.$router.back()))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.vm.tapeSizeDisplay,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.tapeSizeDisplay) = $event)),
                label: "Tape Size",
                density: "comfortable",
                "hide-details": "",
                onChange: _cache[1] || (_cache[1] = ($event: any) => {_ctx.vm.setTapeSize($event.target.value); _ctx.vm.clearSaved()})
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.vm.settings.tapeOverflow,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vm.settings.tapeOverflow) = $event)),
                  _cache[3] || (_cache[3] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "Tape Over-/Underflow",
                items: _ctx.vm.tapeOverflowItems,
                "item-title": "title",
                "item-value": "value",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.vm.cellSizeDisplay,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.vm.cellSizeDisplay) = $event)),
                label: "Cell Size",
                density: "comfortable",
                "hide-details": "",
                onChange: _cache[5] || (_cache[5] = ($event: any) => {_ctx.vm.setCellSize($event.target.value); _ctx.vm.clearSaved()})
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                modelValue: _ctx.vm.cellSizeBitsDisplay,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.vm.cellSizeBitsDisplay) = $event)),
                label: "Cell Size Bits",
                density: "comfortable",
                "hide-details": "",
                onChange: _cache[7] || (_cache[7] = ($event: any) => {_ctx.vm.setCellSizeBits($event.target.value); _ctx.vm.clearSaved()})
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_checkbox, {
                modelValue: _ctx.vm.settings.signedCell,
                "onUpdate:modelValue": [
                  _cache[8] || (_cache[8] = ($event: any) => ((_ctx.vm.settings.signedCell) = $event)),
                  _cache[9] || (_cache[9] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "Signed Cell Values",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.vm.settings.cellOverflow,
                "onUpdate:modelValue": [
                  _cache[10] || (_cache[10] = ($event: any) => ((_ctx.vm.settings.cellOverflow) = $event)),
                  _cache[11] || (_cache[11] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "Cell Over-/Underflow",
                items: _ctx.vm.cellOverflowItems,
                "item-title": "title",
                "item-value": "value",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.vm.settings.characterSet,
                "onUpdate:modelValue": [
                  _cache[12] || (_cache[12] = ($event: any) => ((_ctx.vm.settings.characterSet) = $event)),
                  _cache[13] || (_cache[13] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "Character Set",
                items: _ctx.vm.characterSetItems,
                "item-title": "title",
                "item-value": "value",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.vm.settings.eof,
                "onUpdate:modelValue": [
                  _cache[14] || (_cache[14] = ($event: any) => ((_ctx.vm.settings.eof) = $event)),
                  _cache[15] || (_cache[15] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "End of Input",
                items: _ctx.vm.endOfInputItems,
                "item-title": "title",
                "item-value": "value",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_select, {
                modelValue: _ctx.vm.settings.newLine,
                "onUpdate:modelValue": [
                  _cache[16] || (_cache[16] = ($event: any) => ((_ctx.vm.settings.newLine) = $event)),
                  _cache[17] || (_cache[17] = ($event: any) => (_ctx.vm.clearSaved()))
                ],
                label: "New Lines",
                items: _ctx.vm.newLinesItems,
                "item-title": "title",
                "item-value": "value",
                density: "comfortable",
                "hide-details": ""
              }, null, 8, ["modelValue", "items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "text-none",
                onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.$router.back())),
                block: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Close")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "text-none",
                onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.vm.save())),
                block: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Save")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.vm.saved)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_alert, {
                    ref: "savedAlert",
                    modelValue: _ctx.vm.saved,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.vm.saved) = $event)),
                    text: "Settings saved",
                    type: "success",
                    variant: "tonal",
                    closable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.vm.error)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_alert, {
                    ref: "errorAlert",
                    text: _ctx.vm.error.toString(),
                    type: "error",
                    variant: "tonal"
                  }, null, 8, ["text"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}