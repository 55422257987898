import { EditorView, basicSetup } from "codemirror";
import { EditorState, StateField, StateEffect, StateEffectType, Prec } from '@codemirror/state';
import { ViewUpdate } from '@codemirror/view';
import { markRaw } from "vue";

export default class BfTextAreaViewModel {

    private _view?: EditorView;
    private _editableEffect?: StateEffectType<boolean>;
    private _readonly: boolean = false;

    public updateModelValue: (value: string) => void = () => {};

    public get source(): string {
        return this._view?.state.doc.toString() || '';
    }

    public set source(value: string) {
        if (!this._view) {
            throw new Error('Text area not yet created');
        }
        this._view.dispatch({ changes: {from: 0, to: this._view.state.doc.length, insert: value }});
    }

    public get readonly(): boolean {
        return this._readonly;
    }

    public set readonly(value: boolean) {
        if (this._readonly === value) {
            return;
        }
        if (!this._view || !this._editableEffect) {
            throw new Error('Text area not yet created');
        }
        this._readonly = value;
        this._view.dispatch({ effects: this._editableEffect.of(!value) });
    }

    public async initialize(id: string, height: string): Promise<void> {
        const div = document.querySelector("#" + id);
        if (!div) {
            throw new Error('Text area mount element not found');
        }
        const updateListener = EditorView.updateListener.of((v: ViewUpdate) => {
            if (!v.docChanged) {
                return;
            }
            this.updateModelValue(this.source);
        });
        const theme = EditorView.theme({
            "&": {
                font: "monospace",
            },
            "&.cm-focused": {
                outline: "none",
            },
            "&.cm-editor": {
                height: height,
            },
            "&.cm-scroller": {
                overflow: "auto",
            },
        });
        const editableEffect = this._editableEffect = markRaw(StateEffect.define<boolean>());
        const editable = StateField.define({
            create() {
                return true;
            },
            update(value, transaction) {
                const effect: StateEffect<boolean> | undefined = transaction.effects.find((effect) => effect.is(editableEffect));
                return effect?.value ?? value;
            },
            provide: f => EditorView.editable.from(f),
        })
        const state = EditorState.create({
            extensions: [
                basicSetup,
                updateListener,
                theme,
                editable,
            ],
        });
        this._view = markRaw(new EditorView({
            parent: div,
            state: state,
            scrollTo: EditorView.scrollIntoView(0),
        }));
    }

    public scrollTo(index: number) {
        if (!this._view) {
            throw new Error('Text area not yet created');
        }
        this._view.dispatch({
            effects: EditorView.scrollIntoView(index),
        });
    }
}
