
export type ModelCreate = {
    id?: number,
    created?: Date,
    modified?: Date,
};
export type Model = {
    id: number
    created: Date,
    modified?: Date,
};

export default class Store<TCreate extends ModelCreate, T extends Model & TCreate> {

    protected _db: IDBDatabase;
    protected _storeName: string;

    public constructor(db: IDBDatabase, storeName: string) {
        this._db = db;
        this._storeName = storeName;
    }

    public get(id: number): Promise<T> {
        return new Promise((resolve, reject) => {
            const request = this._db.transaction(this._storeName)
                .objectStore(this._storeName)
                .get(id);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    public getAll(query: IDBKeyRange | undefined = undefined, count: number | undefined = undefined): Promise<T[]> {
        return new Promise((resolve, reject) => {
            const request = this._db.transaction(this._storeName)
                .objectStore(this._storeName)
                .getAll(query, count);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
        });
    }

    public add(obj: TCreate): Promise<number> {
        return new Promise((resolve, reject) => {
            const o = structuredClone(obj); // Remove reactivity of object
            if (o.id == null) {
                delete o.id;
            }
            o.created = new Date();
            delete o.modified;
            const request = this._db.transaction(this._storeName, "readwrite")
                .objectStore(this._storeName)
                .add(o);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result as number);
        });
    }

    public put(obj: TCreate | T): Promise<number> {
        return new Promise((resolve, reject) => {
            const o = structuredClone(obj); // Remove reactivity of object
            if (o.id == null) {
                delete o.id;
            }
            const transaction = this._db.transaction(this._storeName, "readwrite");
            transaction.onerror = () => reject(transaction.error);
            const store = transaction.objectStore(this._storeName);
            let putRequest: IDBRequest;
            if (o.id != null) {
                const getRequest = store.get(o.id);
                getRequest.onsuccess = () => {
                    if (getRequest.result != null) {
                        o.created = getRequest.result.created;
                        o.modified = new Date();
                    } else {
                        o.created = new Date();
                        delete o.modified;
                    }
                    putRequest = store.put(o);
                    putRequest.onsuccess = () => resolve(putRequest.result as number);
                }
            } else {
                o.created = new Date();
                delete o.modified;
                putRequest = store.put(o);
                putRequest.onsuccess = () => resolve(putRequest.result as number);
            }
        });
    }

    public delte(id: number): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = this._db.transaction(this._storeName, "readwrite")
                .objectStore(this._storeName)
                .delete(id);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
    }
}