<template>
  <layout-default :title="vm.title" :actions="actions" :has-back-button="true" @back="$router.back()">
    <v-row v-if="vm.errorWhileSaving">
      <v-alert :text="vm.errorWhileSaving.toString()" type="error" variant="tonal"></v-alert>
    </v-row>
    <v-row justify="center">
      <bf-code-editor
        ref="codeEditor"
        v-model="vm.src"
        :current-instruction="vm.currentInstruction"
        :readonly="vm.state === 'running' || vm.state === 'debugging' || vm.state === 'hitBreakpoint'">
      </bf-code-editor>
    </v-row>
    <v-row v-if="vm.state === 'error'">
      <v-alert :text="vm.errorMsg" type="error" variant="tonal"></v-alert>
    </v-row>
    <v-row v-if="vm.state === 'reset' || vm.state === 'stopped' || vm.state === 'error'" justify="center">
      <v-col>
        <v-btn class="text-none" @click="vm.reset()" block>Reset</v-btn>
      </v-col>
      <v-col>
        <v-btn class="text-none" @click="vm.run()" block>Run</v-btn>
      </v-col>
      <v-col>
        <v-btn class="text-none" @click="vm.debug()" block>Debug</v-btn>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col>
        <v-btn class="text-none" @click="vm.stop()" block>Stop</v-btn>
      </v-col>
      <v-col>
        <v-btn class="text-none" @click="vm.continue()" block :disabled="vm.state !== 'hitBreakpoint'">Continue</v-btn>
      </v-col>
      <v-col>
        <v-btn
          class="text-none"
          @mousedown="vm.multiStepStart()"
          @touchstart="vm.multiStepStart()"
          @click="vm.multiStepStop(true)"
          @mouseup="vm.multiStepStop(false)"
          @mouseout="vm.multiStepStop(false)"
          @touchend="vm.multiStepStop(false)"
          @touchcancel="vm.multiStepStop(false)"
          block
          :disabled="vm.state !== 'hitBreakpoint'">
          Step
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <bf-text-area
        v-model="vm.input"
        label="Input"
        height="6rem"
        :readonly="vm.state === 'running' || vm.state === 'debugging' || vm.state === 'hitBreakpoint'">
      </bf-text-area>
    </v-row>
    <v-row>
      <bf-memory-viewer
        :tape="vm.tape"
        :tape-size="vm.settings.tapeSize"
        :cell-size="vm.settings.cellSize"
        :signed-cell="vm.settings.signedCell"
        :active-cell="vm.activeCell"
        :readonly="vm.state !== 'hitBreakpoint'"
        :to-char-func="toChar"
        :from-char-func="fromChar"
        @update:active-cell="vm.activeCell = $event">
      </bf-memory-viewer>
    </v-row>
    <v-row justify="center">
      <bf-text-area
        :model-value="vm.output"
        label="Output"
        height="20rem"
        readonly>
      </bf-text-area>
    </v-row>
  </layout-default>
</template>

<script lang="ts">
import { Action } from '@/layouts/layout-default.vue';
import { defineComponent } from 'vue'
import EditorViewModel, { IntegerArray } from './editor-view.vue.model';


export default defineComponent({
  name: 'editor-view',
  data() {
    return {
      vm: new EditorViewModel(),
      actions: [] as Action[],
    }
  },
  async beforeMount() {
    await this.vm.initialize(this.$route.params.id);
    const codeEditor: any = this.$refs.codeEditor;
    this.actions = [
      {
        id: 'scroll-to-code-pointer',
        icon: "mdi-receipt-text-arrow-left-outline",
        disabled: () => this.vm.state === 'reset',
        location: 'bar',
        onClick: () => codeEditor.scrollToCodePointer(),
      },
      {
        id: 'indent-more',
        icon: "mdi-keyboard-tab",
        location: 'bar',
        onClick: () => codeEditor.indentSelectionMore(),
      },
      {
        id: 'indent-less',
        icon: "mdi-keyboard-tab-reverse",
        location: 'bar',
        onClick: () => codeEditor.indentSelectionLess(),
      },
      {
        id: 'settings',
        icon: "mdi-cog",
        title: 'Settings',
        location: 'menu',
        onClick: () => this.$router.push({ name: 'settings' }),
      }
    ];
  },
  methods: {
    toChar(v: number): string {
      return this.vm.toChar(v);
    },
    fromChar(v: string): IntegerArray | undefined {
      return this.vm.fromChar(v);
    },
  },
})
</script>
