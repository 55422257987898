import { Model, ModelCreate } from "./indexed-db/store";

export type Settings = {
    eof: 'return0' | 'return-1' | 'noChange',
    tapeSize: number,
    cellSize: number,
    signedCell: boolean,
    tapeOverflow: 'wrap' | 'ignore' | 'throw',
    cellOverflow: 'wrap' | 'ignore' | 'throw',
    newLine: 'n' | 'rn' | 'r',
    characterSet: 'ascii' | 'utf-8' | 'utf-16',
};

export type BfFileCreate = ModelCreate & {
    title: string,
    content?: {
        sourceCode: string,
        inputValue: string,
        settings?: Settings,
    },
};

export type BfFile = Model & BfFileCreate;

export function fileOk(file?: BfFile): boolean {
    if (!file) {
        return false;
    }
    if (!file.content) {
        file.content = {
            sourceCode: "",
            inputValue: "",
        };
    }
    if (!file.content.settings) {
        file.content.settings = defaultSettings();
    }
    return true;
}

export function defaultSettings(): Settings {
    return {
        eof: "return0",
        tapeSize: 32768,
        cellSize: 256,
        signedCell: false,
        tapeOverflow: "wrap",
        cellOverflow: "wrap",
        newLine: "n",
        characterSet: "ascii",
    };
}
