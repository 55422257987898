import { EditorView, basicSetup } from "codemirror";
import { EditorState, StateField, StateEffect, StateEffectType, Range, Prec } from '@codemirror/state';
import { ViewUpdate, Decoration } from '@codemirror/view';
import { indentMore, indentLess } from '@codemirror/commands'
import { markRaw } from "vue";
import { brainfuck } from "@/codemirror-language/brainfuck/brainfuck";

export default class BfCodeEditorViewModel {

    private _view?: EditorView;
    private _editableEffect?: StateEffectType<boolean>;
    private _highlightEffect?: StateEffectType<Range<Decoration>[]>;
    private _removeHighlightEffect?: StateEffectType<null>;
    private _highlightDecoration?: Decoration;
    private _readonly: boolean = false;

    public updateModelValue: (value: string) => void = () => {};

    public get source(): string {
        return this._view?.state.doc.toString() || '';
    }

    public set source(value: string) {
        if (!this._view) {
            throw new Error('Code editor not yet created');
        }
        this._view.dispatch({ changes: {from: 0, to: this._view.state.doc.length, insert: value }});
    }

    public get readonly(): boolean {
        return this._readonly;
    }

    public set readonly(value: boolean) {
        if (this._readonly === value) {
            return;
        }
        if (!this._view || !this._editableEffect) {
            throw new Error('Code editor not yet created');
        }
        this._readonly = value;
        this._view.dispatch({ effects: this._editableEffect.of(!value) });
    }

    public async initialize(id: string): Promise<void> {
        const div = document.querySelector("#" + id);
        if (!div) {
            throw new Error('Code editor mount element not found');
        }
        const updateListener = EditorView.updateListener.of((v: ViewUpdate) => {
            if (!v.docChanged) {
                return;
            }
            this.updateModelValue(this.source);
        });
        const theme = EditorView.theme({
            "&": {
                font: "monospace",
            },
            "&.cm-focused": {
                outline: "none",
            },
            "&.cm-editor": {
                height: "20rem",
            },
            "&.cm-scroller": {
                overflow: "auto",
            },
            ".curser-position": {
                backgroundColor: "#C62828",
                color: "white",
            },
        });
        const highlightEffect = this._highlightEffect = markRaw(StateEffect.define<Range<Decoration>[]>());
        const removeHighlightEffect = this._removeHighlightEffect = markRaw(StateEffect.define());
        const hightlight = StateField.define({
            create() {
                return Decoration.none;
            },
            update(value, transaction) {
                value = value.map(transaction.changes);

                const removeHighlights = transaction.effects.some((effect) => effect.is(removeHighlightEffect));
                if (removeHighlights) {
                    return Decoration.none;
                }

                for (const effect of transaction.effects) {
                    if (effect.is(highlightEffect)) {
                        value = value.update({ add: effect.value, sort: true });
                    }
                }

                return value;
            },
            provide: f => EditorView.decorations.from(f),
        });
        const editableEffect = this._editableEffect = markRaw(StateEffect.define<boolean>());
        const editable = StateField.define({
            create() {
                return true;
            },
            update(value, transaction) {
                const effect: StateEffect<boolean> | undefined = transaction.effects.find((effect) => effect.is(editableEffect));
                return effect?.value ?? value;
            },
            provide: f => EditorView.editable.from(f),
        })
        const state = EditorState.create({
            extensions: [
                basicSetup,
                updateListener,
                theme,
                editable,
                Prec.highest(hightlight),
                brainfuck(),
            ],
        });
        this._view = markRaw(new EditorView({
            parent: div,
            state: state,
            scrollTo: EditorView.scrollIntoView(0),
        }));

        this._highlightDecoration = markRaw(Decoration.mark({
            attributes: { class: "curser-position" },
        }));
    }

    public hightlightCharAt(index: number | undefined) {
        if (!this._view || !this._highlightEffect || !this._removeHighlightEffect || !this._highlightDecoration) {
            throw new Error('Code editor not yet created');
        }
        this._view.dispatch({
            effects: this._removeHighlightEffect.of(null)
        });
        if (index == null || index < 0 || index >= this.source.length) {
            return;
        }
        this._view.dispatch({
            effects: this._highlightEffect.of([
                this._highlightDecoration.range(index, index + 1)
            ])
        });
    }

    public scrollTo(index: number) {
        if (!this._view) {
            throw new Error('Code editor not yet created');
        }
        this._view.dispatch({
            effects: EditorView.scrollIntoView(index),
        });
    }

    public indentSelection(indent: 'more' | 'less') {
        if (!this._view) {
            throw new Error('Code editor not yet created');
        }
        const indentFunc = indent === 'more' ? indentMore : indentLess;
        indentFunc({
            state: this._view.state,
            dispatch: transaction => this._view?.update([transaction]),
        })
    }
}
