// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "'bOYQPOOOOOO'#Ca'#CaOkOQO'#C`OOQO'#Cl'#ClOYQPOOOOQO'#Cu'#CuQvQPOOO!XOQO'#CbO!dOQO'#CmO!oOQO,58zOOQO,58z,58zO!oOQO,58zOOQO-E6j-E6jOOQO'#Cn'#CnO!wQPO'#CvOOQO'#Cj'#CjO#RQPO'#CiO#YQPO'#CwO#qQPO'#CvQOQPOOOOOO,58|,58|O$VOQO,58|O$VOQO,58|OOOO,59X,59XOOOO-E6k-E6kOOQO1G.f1G.fO$_OQO1G.fOOQO-E6l-E6lO$gQPO,59bO${QPO,59bOOQO'#Ck'#CkOOQO,59T,59TO%WQPO,59TO%]QPO,59cO%tQPO,59bO$gQPO,59bOOOO1G.h1G.hO%{OQO1G.hOOQO7+$Q7+$QO&TQPO,59ZO&lQPO1G.|OOQO-E6m-E6mOOQO1G.o1G.oOOQO,59[,59[O'QQPO1G.|OOQO-E6n-E6nOOOO7+$S7+$SP'XQPO'#CoPvQPO'#Cn",
  stateData: "'g~OPOSQOS~OXTOYTOZTO[TOgPO~OVVOWYOhZO~OXTOYTOZTO[TOg_O~OVVOWdOhfO~OhgOVaXWaX~OVVOWiO~OejXljX~PvOlnO~PvOgPOXkXYkXZkX[kXekXlkX~OXTOYTOZTO[TOejXljX~OVVOWtO~OVVOWvO~OXTOYTOZTO[TOejalja~Og_Oejalja~OlnO~OgPOXkaYkaZka[kaekalka~Og_O~P$gOVVOW!OO~OXTOYTOZTO[TOecagcalca~OXTOYTOZTO[TOejilji~Og_O~P&lOXTOYTOZTO[TO~O",
  goto: "$vlPPPPmsyPPPPPP!T!^!g!m!w#Z#q#wPPPP#}$a$gXROSaqXQOSaqaWQVXZefju_aU^`mr|!Q_`U^`mr|!QQo`RzpQSOS[SqRqaQXQQeVWhXejuQjZRufS^U`Yk^rw|!QQrbUwlx!PR|sQm^RymQsbR}sSUOSi]U^`blrswx|!P!QQcURp`SbU`Ql^QxmV{r|!Q",
  nodeNames: "⚠ Comment Debugger Program LoopComment CommentStart LoopComment CommentStart CommentEnd IncDec Move Print Read Loop LoopStart LoopEnd",
  maxTerm: 28,
  skippedNodes: [0,1,2],
  repeatNodeCount: 5,
  tokenData: "%S~RfOY!gYZ#eZ]!g]^#j^s!gst#xt{!g{|$Q|}$]}!O$Q!O!P$e!P!^!g!^!_$m!_!`!g!`!a$m!a!}!g!}#O$x#O#P!g#P#Q$}#Q;'S!g;'S;=`#r<%lO!g~!l]P~OY!gYZ#eZ]!g]^#j^s!gt{!g!P!^!g!_!`!g!a!}!g#O#P!g#Q;'S!g;'S;=`#r<%lO!g~#jOP~~#oPP~YZ#e~#uP;=`<%l!g~#}PQ~st#x~$VQX~{|$Q}!O$Q~$bP[~|}$]~$jPZ~!O!P$e~$rQY~!^!_$m!`!a$m~$}Og~~%SOl~",
  tokenizers: [1, new LocalTokenGroup("d~RQ!}#OX#P#Q^~^OV~~cOW~~", 19, 24)],
  topRules: {"Program":[0,3]},
  tokenPrec: 0
})
