import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_bf_code_editor = _resolveComponent("bf-code-editor")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_bf_text_area = _resolveComponent("bf-text-area")!
  const _component_bf_memory_viewer = _resolveComponent("bf-memory-viewer")!
  const _component_layout_default = _resolveComponent("layout-default")!

  return (_openBlock(), _createBlock(_component_layout_default, {
    title: _ctx.vm.title,
    actions: _ctx.actions,
    "has-back-button": true,
    onBack: _cache[15] || (_cache[15] = ($event: any) => (_ctx.$router.back()))
  }, {
    default: _withCtx(() => [
      (_ctx.vm.errorWhileSaving)
        ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_alert, {
                text: _ctx.vm.errorWhileSaving.toString(),
                type: "error",
                variant: "tonal"
              }, null, 8, ["text"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_code_editor, {
            ref: "codeEditor",
            modelValue: _ctx.vm.src,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.vm.src) = $event)),
            "current-instruction": _ctx.vm.currentInstruction,
            readonly: _ctx.vm.state === 'running' || _ctx.vm.state === 'debugging' || _ctx.vm.state === 'hitBreakpoint'
          }, null, 8, ["modelValue", "current-instruction", "readonly"])
        ]),
        _: 1
      }),
      (_ctx.vm.state === 'error')
        ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_v_alert, {
                text: _ctx.vm.errorMsg,
                type: "error",
                variant: "tonal"
              }, null, 8, ["text"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.vm.state === 'reset' || _ctx.vm.state === 'stopped' || _ctx.vm.state === 'error')
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 2,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.vm.reset())),
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Reset")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.vm.run())),
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Run")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.vm.debug())),
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Debug")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_v_row, {
            key: 3,
            justify: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.vm.stop())),
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Stop")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.vm.continue())),
                    block: "",
                    disabled: _ctx.vm.state !== 'hitBreakpoint'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Continue")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    class: "text-none",
                    onMousedown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.vm.multiStepStart())),
                    onTouchstart: _cache[7] || (_cache[7] = ($event: any) => (_ctx.vm.multiStepStart())),
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.vm.multiStepStop(true))),
                    onMouseup: _cache[9] || (_cache[9] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                    onMouseout: _cache[10] || (_cache[10] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                    onTouchend: _cache[11] || (_cache[11] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                    onTouchcancel: _cache[12] || (_cache[12] = ($event: any) => (_ctx.vm.multiStepStop(false))),
                    block: "",
                    disabled: _ctx.vm.state !== 'hitBreakpoint'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Step ")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_text_area, {
            modelValue: _ctx.vm.input,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.vm.input) = $event)),
            label: "Input",
            height: "6rem",
            readonly: _ctx.vm.state === 'running' || _ctx.vm.state === 'debugging' || _ctx.vm.state === 'hitBreakpoint'
          }, null, 8, ["modelValue", "readonly"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_bf_memory_viewer, {
            tape: _ctx.vm.tape,
            "tape-size": _ctx.vm.settings.tapeSize,
            "cell-size": _ctx.vm.settings.cellSize,
            "signed-cell": _ctx.vm.settings.signedCell,
            "active-cell": _ctx.vm.activeCell,
            readonly: _ctx.vm.state !== 'hitBreakpoint',
            "to-char-func": _ctx.toChar,
            "from-char-func": _ctx.fromChar,
            "onUpdate:activeCell": _cache[14] || (_cache[14] = ($event: any) => (_ctx.vm.activeCell = $event))
          }, null, 8, ["tape", "tape-size", "cell-size", "signed-cell", "active-cell", "readonly", "to-char-func", "from-char-func"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { justify: "center" }, {
        default: _withCtx(() => [
          _createVNode(_component_bf_text_area, {
            "model-value": _ctx.vm.output,
            label: "Output",
            height: "20rem",
            readonly: ""
          }, null, 8, ["model-value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "actions"]))
}