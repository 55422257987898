import { BfFile, BfFileCreate } from "../file";
import Store from "./store";

export type FilesStore = Store<BfFileCreate, BfFile>;

export class DB {

    private static readonly DB_NAME = "BrainF_DB";
    private static readonly FILES_STORE_NAME = "files";

    private _db?: IDBDatabase;
    private _filesStore?: FilesStore;

    public constructor() {
    }

    private createDB(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = window.indexedDB.open(DB.DB_NAME, 1);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve(request.result);
            request.onupgradeneeded = () => {
                const db = request.result;
                db.createObjectStore(DB.FILES_STORE_NAME, { keyPath: "id", autoIncrement: true });
            }
        });
    }

    public dropDB(): Promise<void> {
        return new Promise((resolve, reject) => {
            this._db?.close();
            const request = window.indexedDB.deleteDatabase(DB.DB_NAME);
            request.onerror = () => reject(request.error);
            request.onsuccess = () => resolve();
        });
    }

    public async files(): Promise<FilesStore> {
        if (!this._filesStore) {
            if (!this._db) {
                this._db = await this.createDB();
            }
            this._filesStore = new Store<BfFileCreate, BfFile>(this._db, DB.FILES_STORE_NAME);
        }
        return this._filesStore;
    }
}

export const db = new DB();
