<template>
  <v-sheet class="text-area pa-4 my-2" color="grey-lighten-3" rounded>
    <v-label>{{ label }}</v-label>
    <div :id="id" class="text-area"></div>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BfTextAreaViewModel from './bf-text-area.vue.model';

export default defineComponent({
  name: 'bf-text-area',
  props: {
    label: { type: String, required: true },
    height: { type: String, required: true },
    modelValue: { type: String, required: true },
    readonly: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      vm: new BfTextAreaViewModel(),
    }
  },
  computed: {
    id(): string {
      return 'textArea_' + this.$.uid;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue === this.vm.source) {
        return;
      }
      this.vm.source = this.modelValue;
    },
    readonly() {
      this.vm.readonly = this.readonly;
    },
  },
  async mounted() {
    await this.vm.initialize(this.id, this.height);
    this.vm.updateModelValue = (newValue) => this.$emit('update:modelValue', newValue);
    this.vm.source = this.modelValue;
    this.vm.readonly = this.readonly;
  },
  methods: {
  }
})
</script>

<style scoped>

  .text-area {
    width: 100%;
    outline: none;
  }

</style>
