<template>
  <v-dialog
      v-model="vm.editDialog"
      width="auto"
      min-width="250"
      max-width="400"
      persistent>
    <v-card
        :title="`Edit Cell ${ vm.editCell }`">
      <v-container>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vm.editDezValue"
              label="Dezimal Value"
              density="comfortable"
              hide-details
              @change="vm.setEditCellValueByDezValue($event.target.value)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="vm.editHexValue"
              label="Hexadezimal Value"
              density="comfortable"
              hide-details
              @change="vm.setEditCellValueByHexValue($event.target.value)">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
                class="output-font-input output-font-span"
                :items="vm.editCharItems"
                v-model="vm.editCharDisplayItem"
                v-model:search="vm.editCharSearch"
                label="Character"
                item-title="search"
                density="comfortable"
                no-data-text="Enter a character"
                no-filter
                hide-details
                auto-select-first
                return-object
                @update:model-value="vm.setEditCellValueByCharItem($event)">
              <template v-slot:selection="data">
                <span class="mr-1 output-font">{{ data.item.raw.search }}</span>
                <span v-if="data.item.raw.data && data.item.raw.data.length >= 2">
                  <span :class="['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]" v-for="(d, i) in data.item.raw.data" :key="i">
                      {{ d }}
                  </span>
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item v-bind="data.props" role="option" :title="undefined">
                  <v-list-item-title>
                    <span class="mr-1 output-font">{{ data.item.raw.search }}</span>
                    <span class="text-error no-charachter-data" v-if="!data.item.raw.data">
                      Cannot be printed
                    </span>
                    <span v-if="data.item.raw.data && data.item.raw.data.length >= 2">
                      <span :class="['ml-1', 'character-data', i != data.item.raw.index ? 'other-charachter-data' : undefined]" v-for="(d, i) in data.item.raw.data" :key="i">
                          {{ d }}
                      </span>
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="vm.editSetAsActiveCell"
              label="Active Cell"
              density="comfortable"
              :disabled="!vm.editCanChangeActiveCell"
              hide-details>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn class="ms-auto text-none" @click="vm.closeEditDialog()">Cancel</v-btn>
        <v-btn class="text-none" @click="vm.saveEditDialog()">Save</v-btn>
      </template>
    </v-card>
  </v-dialog>
  <v-sheet class="memory-viewer pa-4 my-2" color="grey-lighten-3" rounded>
    <v-label>Memory</v-label>
    <v-row>
      <v-col>
        <div class="cell-tape d-flex flex-row align-stretch">
          <v-sheet
              v-for="i in vm.cellsOnPage"
              :key="i"
              ref="cells"
              class="cell"
              :color="vm.activeCell === (vm.firstDisplayedCell + i - 1) ? 'red-darken-3' : 'grey-lighten-1'"
              rounded>
            <v-container class="cell-container">
              <v-row class="d-flex justify-space-between align-center">
                <h5>Cell {{ vm.firstDisplayedCell + i - 1 }}</h5>
                <v-btn
                    class="ml-2"
                    icon="mdi-pencil"
                    size="small"
                    density="compact"
                    variant="text"
                    :disabled="vm.readonly"
                    @click="vm.openEditDialog(vm.firstDisplayedCell + i - 1)">
                </v-btn>
              </v-row>
              <v-row>
                <table>
                  <tr>
                    <td>
                      <v-label class="value-lable">Dez</v-label>
                    </td>
                    <td>
                      {{ vm.getCellDisplayDez(vm.firstDisplayedCell + i - 1) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label class="value-lable">Hex</v-label>
                    </td>
                    <td>
                      {{ vm.getCellDisplayHex(vm.firstDisplayedCell + i - 1) }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-label class="value-lable">Char</v-label>
                    </td>
                    <td class="output-font">
                      {{ vm.getCellDisplayChar(vm.firstDisplayedCell + i - 1) }}
                    </td>
                  </tr>
                </table>
              </v-row>
            </v-container>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="4">
        <v-btn class="text-none" :disabled="vm.activeCell == null" @click="vm.scrollToActiveCell($refs.cells)" block>Scroll To Active Cell</v-btn>
      </v-col>
      <v-col cols="12" sm="8">
        <bf-pagination
            v-model="vm.page"
            :item-count="vm.tapeSize"
            v-model:page-size="vm.pageSize"
            :maxPageSize="1024"
            @items-on-page="vm.cellsOnPage = $event"
            @first-item-on-page-index="vm.firstDisplayedCell = $event">
        </bf-pagination>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import BfMemoryViewerViewModel from './bf-memory-viewer.vue.model';
import { IntegerArray } from '@/pages/editor/editor-view.vue.model';

export default defineComponent({
  name: 'bf-memory-viewer',
  props: {
    tape: { type: Object as PropType<IntegerArray>, required: false },
    tapeSize: { type: Number, required: true },
    cellSize: { type: Number, required: true },
    signedCell: { type: Boolean, required: true },
    activeCell: { type: Number as PropType<number | undefined>, default: undefined },
    readonly: { type: Boolean, default: false },
    toCharFunc: { type: Function as PropType<(v: number) => string>, default: undefined }, // eslint-disable-line no-unused-vars
    fromCharFunc: { type: Function as PropType<(v: string) => IntegerArray | undefined>, default: undefined }, // eslint-disable-line no-unused-vars
  },
  emits: ['update:activeCell'],
  data() {
    return {
      vm: new BfMemoryViewerViewModel(),
    }
  },
  watch: {
    tape() {
      this.vm.tape = this.tape;
    },
    activeCell() {
      this.vm.activeCell = this.activeCell
    },
    readonly() {
      this.vm.readonly = this.readonly;
    },
    "vm.activeCell"() {
      this.$emit('update:activeCell', this.vm.activeCell);
    },
  },
  async beforeMount() {
    await this.vm.initialize();
    this.vm.tape = this.tape;
    this.vm.tapeSize = this.tapeSize;
    this.vm.cellSize = this.cellSize;
    this.vm.signedCell = this.signedCell;
    this.vm.activeCell = this.activeCell;
    this.vm.readonly = this.readonly;
    this.vm.toCharFunc = this.toCharFunc;
    this.vm.fromCharFunc = this.fromCharFunc;
  },
})
</script>

<style>
  .output-font-input input {
    font-family: monospace;
  }

  .output-font-span span {
    font-family: monospace;
  }
</style>

<style scoped>
  .memory-viewer {
    width: 100%;
  }

  .cell-tape {
    overflow: auto !important;
  }

  .cell {
    margin: 2px;
    padding: 4px;
    min-width: fit-content;
    white-space: nowrap;
  }

  .cell-container {
    min-width: 75px;
  }

  .value-lable {
    font-size: 0.75em;
  }

  table {
    border-spacing: 4px 0px;
  }

  .output-font {
    font-family: monospace;
  }

  .character-data {
    color: gray;
  }

  .other-charachter-data, .no-charachter-data {
    font-size: 0.75em;
  }

</style>
