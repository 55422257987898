import { IntegerArray } from "@/pages/editor/editor-view.vue.model";
import { nextTick } from "vue";

type EditCharItem = { search: string, data?: string[], index?: number, value?: number }

export default class BfMemoryViewerViewModel {

    public tape?: IntegerArray;
    public tapeSize: number = 0;
    public cellSize: number = 0;
    public signedCell: boolean = false;
    public activeCell: number | undefined = undefined;
    public readonly: boolean = false;
    public page: number = 1;
    public pageSize: number = 128;
    public firstDisplayedCell: number = 0;
    public cellsOnPage: number = 1;
    public toCharFunc?: (v: number) => string;
    public fromCharFunc?: (v: string) => IntegerArray | undefined;

    public editDialog: boolean = false;
    public editCell: number = 0;
    public editDezValue: number = 0;
    public editHexValue: string = "";
    public editCharDisplayItem?: EditCharItem = undefined;
    public editSetAsActiveCell: boolean = false;
    public editCanChangeActiveCell: boolean = false;
    private _innerEditCharSearch: string = "";
    private _editCellValue: number = 0;

    public get editCharSearch(): string {
        return this._innerEditCharSearch;
    }

    public set editCharSearch(v: string) {
        if (!v || v.length < 1) {
            this._innerEditCharSearch = "";
            return;
        }
        const index = v.length - 1 + ((v.charCodeAt(v.length - 1) & 0xFC00) == 0xDC00 ? -1 : 0)
        const searchCode = v.codePointAt(index);
        if (!searchCode) {
            this._innerEditCharSearch = "";
            return;
        }
        this._innerEditCharSearch = String.fromCodePoint(searchCode);
    }

    public get editCharItems(): EditCharItem[] {
        const data = this.fromCharFunc ? this.fromCharFunc(this.editCharSearch) : undefined;
        if (!data) {
            return [
                { search: this.editCharSearch, value: undefined }
            ];
        }
        return Array.from(data).map((v, index, d) => {
            return { search: this.editCharSearch, data: d.map(this.convertToHex), index: index, value: v };
        });
    }

    public async initialize(): Promise<void> {
    }

    public getCellDisplayDez(i: number): number {
        return this.convertToDez(this.tape?.[i] ?? 0);
    }

    public getCellDisplayHex(i: number): string {
        return this.convertToHex(this.tape?.[i] ?? 0);
    }

    public getCellDisplayChar(i: number): string {
        return this.convertToChar(this.tape?.[i] ?? 0);
    }

    public openEditDialog(i: number) {
        this.editDialog = true;
        this.editCell = i;
        this._editCellValue = this.tape?.[i] ?? 0;
        this.editCanChangeActiveCell = this.editCell !== this.activeCell;
        this.editSetAsActiveCell = !this.editCanChangeActiveCell;
        this.editCharDisplayItem = undefined;
        this.updateEditDisplay();
    }

    public closeEditDialog() {
        this.editDialog = false;
    }

    public saveEditDialog() {
        this.editDialog = false;
        if (this.tape) {
            this.tape[this.editCell] = this._editCellValue;
            const temp = this.tape;
            this.tape = undefined;
            this.tape = temp;
        }
        if (this.editCanChangeActiveCell && this.editSetAsActiveCell) {
            this.activeCell = this.editCell;
        }
    }

    public setEditCellValueByDezValue(value: string | number) {
        const parsedValue = typeof value === 'number' ? value : Number.parseInt(value, 10);
        if (!isNaN(parsedValue)) {
            this._editCellValue = ((parsedValue % this.cellSize) + this.cellSize) % this.cellSize;
        }
        this.updateEditDisplay();
    }

    public setEditCellValueByHexValue(value: string) {
        const parsedValue = Number.parseInt(value, 16);
        if (!isNaN(parsedValue)) {
            this._editCellValue = ((parsedValue % this.cellSize) + this.cellSize) % this.cellSize;
        }
        this.updateEditDisplay();
    }

    public setEditCellValueByCharItem(value: EditCharItem) {
        if (value?.value) {
            this._editCellValue = value.value;
        }
        this.updateEditDisplay();
    }

    public async scrollToActiveCell(cells: any) {
        if (this.activeCell == null) {
            return;
        }
        this.page = Math.floor(this.activeCell / this.pageSize) + 1;
        await nextTick();
        cells[this.activeCell - this.firstDisplayedCell].$el.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
    }

    private updateEditDisplay() {
        this.editDezValue = this.convertToDez(this._editCellValue);
        this.editHexValue = this.convertToHex(this._editCellValue);
        if (!this.editCharDisplayItem || this.editCharDisplayItem.value != this._editCellValue) {
            this.editCharDisplayItem = { search: this.convertToChar(this._editCellValue), value: this._editCellValue };
        }
    }

    private convertToDez(value: number): number {
        if (this.signedCell && value >= Math.ceil(this.cellSize / 2)) {
            value -= this.cellSize;
        }
        return value;
    }

    private convertToHex(value: number): string {
        return value.toString(16).toUpperCase();
    }

    private convertToChar(value: number): string {
        return this.toCharFunc ? this.toCharFunc(value) : "";
    }
}