import { createRouter, createWebHistory } from 'vue-router'
import FilesView from './pages/files/files-view.vue'
import EditorView from './pages/editor/editor-view.vue'
import SettingsView from './pages/settings/settings-view.vue'

const routes = [
  { path: '/', name: 'files', component: FilesView },
  { path: '/editor/:id', name: 'editor', component: EditorView },
  { path: '/settings/:id', name: 'settings', component: SettingsView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router