import { ToWorkerMessage, FromWorkerMessage, IntegerArray } from "@/pages/editor/editor-view.vue.model";

class BrainfuckWorker {

    private _tape!: IntegerArray;
    private _tapePointer: number = 0;
    private _codePointer: number = 0;
    private _output: number[] = [];
    private _input?: IntegerArray;
    private _inputPointer: number = 0;
    private _executedInstrs: number = 0;
    private _lastCheckedExecutedInstrs: number = 0;
    private _lastSentDataTime: number = 0;
    private _wait: boolean = false;
    private _singleStep: boolean = false;
    private _maxDataSend: number = 3 * 65536 + 3; // 3 (max. bytes per utf-8 character for a single 16-bit utf-16 character) * 65536 (max. output size) + 3 (max. bytes per incomplete uft-8 charcter)
    
    constructor() {
        addEventListener("message", (event) => this.onMessage(event));
        // @ts-ignore: Cannot find name
        __TAPE_INIT__
    }

    private onMessage(event: MessageEvent<ToWorkerMessage>) {
        const msg = event.data;
        switch (msg.type) {
            case 'input':
                this.setInput(msg.data.input);
                break;
            case 'data':
                this.setData(msg.data.tape, msg.data.activeCell);
                break;
            case 'run':
                this.run();
                break;
            case 'continue':
                this.continue();
                break;
            case 'step':
                this.step();
                break;
        }
    }

    private setInput(input: IntegerArray) {
        this._input = input;
    }

    private setData(tape: IntegerArray | undefined, activeCell: number | undefined) {
        if (tape != null) {
            this._tape = tape;
        }
        if (activeCell != null) {
            this._tapePointer = activeCell;
        }
    }

    private async run() {
        if (this._input == null) {
            throw new Error("Input is not supplied");
        }
        this._lastSentDataTime = performance.now();
        // @ts-ignore: Cannot find name
        __RUN__
        this.sendData();
        this.sendMessage({ type: 'end' });
    }

    private continue() {
        this._lastSentDataTime = performance.now();
        this._singleStep = false;
        this._wait = false;
    }

    private step() {
        this._lastSentDataTime = performance.now();
        this._singleStep = true;
        this._wait = false;
    }

    // @ts-ignore: Member implicitly has 'any' type
    __FUNCS__

    // @ts-ignore: Member implicitly has 'any' type
    __STEPS__

    private sendData() {
        if (this._output.length > this._maxDataSend) {
            this._output = this._output.slice(-this._maxDataSend);
        }
        this.sendMessage({ type: 'data', data: { output: this._output, tape: this._tape, activeCell: this._tapePointer, currentInstruction: this._codePointer } });
        this._output = [];
    }

    private sendDataIfNecessary(codePointer: number, executedInstr: number) {
        this._executedInstrs += executedInstr;
        if (this._executedInstrs - this._lastCheckedExecutedInstrs < 100_000) {
            return;
        }
        this._lastCheckedExecutedInstrs += 100_000;
        if (performance.now() - this._lastSentDataTime < 100) {
            return;
        }
        this._lastSentDataTime += 100;
        if (performance.now() - this._lastSentDataTime >= 100) {
            console.warn("Data not sent frequntly enough!");
            this._lastSentDataTime = performance.now();
        }
        this._codePointer = codePointer;
        this.sendData();
    }

    private sendErrorTapeOverflow(codePointer: number) {
        this._codePointer = codePointer;
        this.sendData();
        this.sendMessage({ type: 'error', data: { msg: 'Tape overflow' }});
    }

    private sendErrorTapeUnderflow(codePointer: number) {
        this._codePointer = codePointer;
        this.sendData();
        this.sendMessage({ type: 'error', data: { msg: 'Tape underflow' }});
    }

    private sendErrorCellOverflow(codePointer: number) {
        this._codePointer = codePointer;
        this.sendData();
        this.sendMessage({ type: 'error', data: { msg: 'Cell overflow' }});
    }

    private sendErrorCellUnderflow(codePointer: number) {
        this._codePointer = codePointer;
        this.sendData();
        this.sendMessage({ type: 'error', data: { msg: 'Cell underflow' }});
    }

    private async wait(codePointer: number) {
        this._codePointer = codePointer;
        this.sendData();
        this.sendMessage({ type: 'breakpoint' });

        this._wait = true;
        while (this._wait) {
            await new Promise(r => setTimeout(r, 100));
        }
    }

    private sendMessage(message: FromWorkerMessage) {
        postMessage(message);
    }
}

export function getWorkerCode(): string {
    return BrainfuckWorker.toString() + '(' + (function() { new BrainfuckWorker(); }).toString() + ')()';
}
