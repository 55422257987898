<template>
  <v-dialog
      v-model="vm.showPersistentStorageDialog"
      width="auto"
      min-width="250"
      max-width="500">
    <v-card
        title="Persistent Storage">
      <v-container>
        <v-row>
          <v-col>
            <p class="text-justify">
              All data is stored in the browser.
              To prevent your browser from deleting your files when memory runs low,
              enable persistent storage.
            </p>
            <p class="text-justify">
              This does not prevent you from manually deleting browser data for this domain.
              There is no guarantee that your files will not be deleted or will remain accesable
              even if persistent storage is enabled.
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex align-center">
            <v-icon
              class="mr-2"
              :icon="vm.isPersistentStorageEnabled ? 'mdi-check-circle' : 'mdi-close-circle'"
              :color="vm.isPersistentStorageEnabled ? 'success' : 'error'"
              size="x-large">
            </v-icon>
            <span>
              Persistent storage is {{ vm.isPersistentStorageEnabled ? 'enabled' : 'disabled' }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="text-none"
              block
              :disabled="vm.isPersistentStorageEnabled"
              @click="vm.enablePersistentStorage()">
              Enable Persistent Storage
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="vm.isPersistentStorageEnabledSuccess === false">
          <v-col>
            <v-alert text="Permission denied for persistent storage" type="error" variant="tonal"></v-alert>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:actions>
        <v-btn class="ms-auto text-none" @click="vm.closePersistentStorageDialog()">Close</v-btn>
      </template>
    </v-card>
  </v-dialog>
  <layout-default :actions="actions" :no-page-scroll="true" @back="$router.back()">
    <v-row class="flex-0-0">
      <v-col cols="12" sm="4">
        <v-btn
            class="text-none"
            prepend-icon="mdi-plus"
            block
            @click="newFile()">
          New File
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-flex flex-column flex-1-1">
      <v-col class="min-height-0">
        <div class="fill-height overflow-y-auto">
          <v-container v-if="vm.files.length == 0" class="pa-8 text-center">
            There are no files<br>Create a new file to start coding
          </v-container>
          <v-sheet
              v-for="file in vm.files"
              :key="file.id"
              class="my-3 mx-5 elevation-2"
              style="overflow-wrap: anywhere;"
              color="grey-lighten-3"
              rounded>
            <v-container class="cell-container">
              <v-row>
                <v-col class="d-flex justify-space-between">
                  <div class="w-100">
                    <v-text-field
                        v-if="vm.renameFileId === file.id"
                        :ref="`renameTextField${file.id}`"
                        v-model="vm.renameNewTitle"
                        label="New File Name"
                        density="comfortable"
                        append-inner-icon="mdi-undo"
                        hide-details
                        @focus="$event.target.select()"
                        @blur="confirmRename()"
                        @click:append-inner="vm.cancleRename()">
                    </v-text-field>
                    <div v-else>
                      <h3>{{ file.title }}</h3>
                    </div>
                    <div>
                      <span>{{ dateToString(file) }}</span>
                    </div>
                  </div>
                  <div>
                    <v-menu ref="fileActionsMenu">
                      <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-dots-vertical" v-bind="props" density="comfortable" variant="text"></v-btn>
                      </template>
                      <v-list density="compact">
                        <v-list-item @click="rename(file.id)">
                          <template v-slot:prepend>
                            <v-icon icon="mdi-pencil"></v-icon>
                          </template>
                          <v-list-item-title>Rename</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="cloneFile(file.id)">
                          <template v-slot:prepend>
                            <v-icon icon="mdi-content-duplicate"></v-icon>
                          </template>
                          <v-list-item-title>Clone</v-list-item-title>
                        </v-list-item>
                        <v-list-item ref="delteMenuItem">
                          <template v-slot:prepend>
                            <v-icon color="red" icon="mdi-delete"></v-icon>
                          </template>
                          <v-list-item-title>Delete</v-list-item-title>
                          <v-dialog activator="parent" max-width="450">
                            <template v-slot:default="{ isActive }">
                              <v-card
                                :text="`Are you sure you want to delete '${file.title}'? This action cannot be undone.`"
                                :title="`Delete '${file.title}'`"
                              >
                                <template v-slot:prepend>
                                  <v-icon color="red" icon="mdi-delete"></v-icon>
                                </template>
                                <template v-slot:actions>
                                  <v-btn
                                    class="text-none ml-auto"
                                    text="Cancle"
                                    @click="isActive.value = false"
                                  ></v-btn>
                                  <v-btn
                                    class="text-none"
                                    text="Delete"
                                    color="red"
                                    @click="isActive.value = false; vm.deleteFile(file.id)"
                                  ></v-btn>
                                </template>
                              </v-card>
                            </template>
                          </v-dialog>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-btn class="text-none" @click="vm.openFile(file.id)" block>Open</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
  </layout-default>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import FilesViewModel from './files-view.vue.model';
import dayjs from 'dayjs';
import { VMenu, VTextField } from 'vuetify/lib/components/index.mjs';
import { BfFile } from '@/utils/file';
import { Action } from '@/layouts/layout-default.vue';

export default defineComponent({
  name: 'files-view',
  data() {
    return {
      vm: new FilesViewModel(),
      actions: [] as Action[],
    }
  },
  async beforeMount() {
    await this.vm.initialize(this.$router);
    this.actions = [
      {
        id: 'persistetStorage',
        icon: "mdi-database",
        title: 'Persistet Storage',
        location: 'menu',
        onClick: () => this.vm.openPersistentStorageDialog(),
      },
    ];
  },
  methods: {
    dateToString(file: BfFile): string {
      return dayjs(file.modified || file.created).format("DD.MM.YYYY");
    },
    async rename(id: number) {
      this.vm.rename(id);
      await this.$nextTick();
      (this.$refs[`renameTextField${id}`] as VTextField[])[0].focus();
    },
    confirmRename() {
      document.addEventListener('mouseup', (e) => {
        this.vm.confirmRename();
        setTimeout(() => {
          (this.$refs.fileActionsMenu as VMenu[]).forEach(m => m.updateLocation?.(e));
        }, 50);
      }, { once: true });
    },
    async newFile() {
      const id = await this.vm.newFile();
      await this.rename(id);
    },
    async cloneFile(id: number) {
      const newId = await this.vm.cloneFile(id);
      await this.rename(newId);
    }
  },
})
</script>

<style scoped>
  .min-height-0 {
    min-height: 0;
  }
</style>
