import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64e94c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_label = _resolveComponent("v-label")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, {
    class: "text-area pa-4 my-2",
    color: "grey-lighten-3",
    rounded: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        id: _ctx.id,
        class: "text-area"
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}